import BaseEntityModel from "./../general/BaseEntityModel";
import defaultImg from "@/assets/images/places.svg";
import SystemComponentInfoData from "./../systemConfiguration/systemComponents/SystemComponentInfoData";
import SystemComponentsHierarchyInfoData from "./../systemConfiguration/systemComponentsHierarchies/SystemComponentsHierarchyInfoData";
import GeographicalDistribution from "./../settings/settingsOthers/geographicalDistributions/GeographicalDistribution";
import FullTreeData from "./FullTreeData";

export default class Place extends BaseEntityModel {
  icon = defaultImg;
  defaultImg = defaultImg;
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue(placeTypeToken = "") {
    this.setInitialValueBaseEntityModel();
    this.mediaFile = "";
    this.placeToken = "";
    this.placeNameCurrent = "";
    this.placeNameEn = "";
    this.placeNameAr = "";
    this.placeNameUnd = "";
    this.placeAddressCurrent = "";
    this.placeAddressAr = "";
    this.placeAddressEn = "";
    this.placeAddressUnd = "";
    this.placeTypeToken = placeTypeToken || "";
    this.placeTypeNameCurrent = "";
    this.placeActivityTypeToken = "";
    this.placeActivityTypeNameCurrent = "";
    this.placePhone1 = "";
    this.placePhone1CountryCodeName = "";
    this.placePhone1CountryCode = "";
    this.placePhone1WithCC = "";
    this.placePhone1WhatsAppStatus = "";
    this.placePhone2 = "";
    this.placePhone2CountryCodeName = "";
    this.placePhone2CountryCode = "";
    this.placePhone2WithCC = "";
    this.placePhone2WhatsAppStatus = "";
    this.placePhone3 = "";
    this.placePhone3CountryCodeName = "";
    this.placePhone3CountryCode = "";
    this.placePhone3WithCC = "";
    this.placePhone3WhatsAppStatus = "";
    this.placePhone4 = "";
    this.placePhone4CountryCodeName = "";
    this.placePhone4CountryCode = "";
    this.placePhone4WithCC = "";
    this.placePhone4WhatsAppStatus = "";
    this.placeEmail = "";
    this.placeMapLink = "";
    this.placeFaceBookLink = "";
    this.placeTwitterLink = "";
    this.placeInstgramLink = "";
    this.placeYouTubeLink = "";
    this.placeWebSiteLink = "";
    this.placeLocalIpAddress = "";
    this.placeFaxNumber = "";
    this.placeTaxNumber = "";
    this.placePostOfficeNameCurrent = "";
    this.placePostOfficeNameAr = "";
    this.placePostOfficeNameEn = "";
    this.placePostOfficeNameUnd = "";
    this.placePostalCode = "";
    this.placeLatitude = "";
    this.placelongitude = "";
    this.placeEndLatitude = "";
    this.placeEndlongitude = "";
    this.placeDistance = "";
    this.placeDescriptionCurrent = "";
    this.placeDescriptionAr = "";
    this.placeDescriptionEn = "";
    this.placeDescriptionUnd = "";
    this.placeOpeningDateTime = "";
    this.placeOpeningDateTimeCustomized = "";
    this.placeOpeningDate = "";
    this.placeOpeningTime = "";
    this.placeOpningDurationCurrent = "";
    this.placeImageIsDefault = "";
    this.placeImagePath = "";
    this.placeImageSizeBytes = "";
    this.placeImageSizeTextCurrent = "";
    this.placeImageSizeTextAr = "";
    this.placeImageSizeTextEn = "";
    this.placeImageSizeTextUnd = "";
    this.placeNotes = "";
    this.placeUsersCount = "";
    this.geographicalDistributionToken = "";
    this.geographicalDistributionData = new GeographicalDistribution();
    this.parentPlaceToken = "";
    this.mainRootToken = "";
    this.rankingUnderParent = "";
    this.parentPlaceData = "";
    this.systemComponentToken = "";
    this.systemComponentHierarchyToken = "";
    this.systemComponentData = new SystemComponentInfoData();
    this.systemComponentsHierarchyData =
      new SystemComponentsHierarchyInfoData();
    this.isHaveChildrenStatus = false;
    this.fullTreeData = new FullTreeData();
  }
  fillData(data) {
    if (data) {
      this.fillDataBaseEntityModel(data);
      this.mediaFile = "";
      this.placeToken = data.placeToken;
      this.placeNameCurrent = data.placeNameCurrent;
      this.placeNameEn = data.placeNameEn;
      this.placeNameAr = data.placeNameAr;
      this.placeNameUnd = data.placeNameUnd;
      this.placeAddressCurrent = data.placeAddressCurrent;
      this.placeAddressAr = data.placeAddressAr;
      this.placeAddressEn = data.placeAddressEn;
      this.placeAddressUnd = data.placeAddressUnd;
      this.placeTypeToken = data.placeTypeToken;
      this.placeTypeNameCurrent = data.placeTypeNameCurrent;
      this.placeActivityTypeToken = data.placeActivityTypeToken;
      this.placeActivityTypeNameCurrent = data.placeActivityTypeNameCurrent;
      this.placePhone1 = data.placePhone1;
      this.placePhone1CountryCodeName = data.placePhone1CountryCodeName;
      this.placePhone1CountryCode = data.placePhone1CountryCode;
      this.placePhone1WithCC = data.placePhone1WithCC;
      this.placePhone1WhatsAppStatus = data.placePhone1WhatsAppStatus;
      this.placePhone2 = data.placePhone2;
      this.placePhone2CountryCodeName = data.placePhone2CountryCodeName;
      this.placePhone2CountryCode = data.placePhone2CountryCode;
      this.placePhone2WithCC = data.placePhone2WithCC;
      this.placePhone2WhatsAppStatus = data.placePhone2WhatsAppStatus;
      this.placePhone3 = data.placePhone3;
      this.placePhone3CountryCodeName = data.placePhone3CountryCodeName;
      this.placePhone3CountryCode = data.placePhone3CountryCode;
      this.placePhone3WithCC = data.placePhone3WithCC;
      this.placePhone3WhatsAppStatus = data.placePhone3WhatsAppStatus;
      this.placePhone4 = data.placePhone4;
      this.placePhone4CountryCodeName = data.placePhone4CountryCodeName;
      this.placePhone4CountryCode = data.placePhone4CountryCode;
      this.placePhone4WithCC = data.placePhone4WithCC;
      this.placePhone4WhatsAppStatus = data.placePhone4WhatsAppStatus;
      this.placeEmail = data.placeEmail;
      this.placeMapLink = data.placeMapLink;
      this.placeFaceBookLink = data.placeFaceBookLink;
      this.placeTwitterLink = data.placeTwitterLink;
      this.placeInstgramLink = data.placeInstgramLink;
      this.placeYouTubeLink = data.placeYouTubeLink;
      this.placeWebSiteLink = data.placeWebSiteLink;
      this.placeLocalIpAddress = data.placeLocalIpAddress;
      this.placeFaxNumber = data.placeFaxNumber;
      this.placeTaxNumber = data.placeTaxNumber;
      this.placePostOfficeNameCurrent = data.placePostOfficeNameCurrent;
      this.placePostOfficeNameAr = data.placePostOfficeNameAr;
      this.placePostOfficeNameEn = data.placePostOfficeNameEn;
      this.placePostOfficeNameUnd = data.placePostOfficeNameUnd;
      this.placePostalCode = data.placePostalCode;
      this.placeLatitude = data.placeLatitude;
      this.placelongitude = data.placelongitude;
      this.placeEndLatitude = data.placeEndLatitude;
      this.placeEndlongitude = data.placeEndlongitude;
      this.placeDistance = data.placeDistance;
      this.placeDescriptionCurrent = data.placeDescriptionCurrent;
      this.placeDescriptionAr = data.placeDescriptionAr;
      this.placeDescriptionEn = data.placeDescriptionEn;
      this.placeDescriptionUnd = data.placeDescriptionUnd;
      this.placeOpeningDateTime = data.placeOpeningDateTime;
      this.placeOpeningDateTimeCustomized = data.placeOpeningDateTimeCustomized;
      this.placeOpeningDate = data.placeOpeningDate;
      this.placeOpeningTime = data.placeOpeningTime;
      this.placeOpningDurationCurrent = data.placeOpningDurationCurrent;
      this.placeImageIsDefault = data.placeImageIsDefault;
      this.placeImagePath = data.placeImagePath;
      this.placeImageSizeBytes = data.placeImageSizeBytes;
      this.placeImageSizeTextCurrent = data.placeImageSizeTextCurrent;
      this.placeImageSizeTextAr = data.placeImageSizeTextAr;
      this.placeImageSizeTextEn = data.placeImageSizeTextEn;
      this.placeImageSizeTextUnd = data.placeImageSizeTextUnd;
      this.placeNotes = data.placeNotes;
      this.placeUsersCount = data.placeUsersCount;
      this.geographicalDistributionToken = data.geographicalDistributionToken;
      this.geographicalDistributionData.fillData(
        data.geographicalDistributionData
      );
      this.parentPlaceToken = data.parentPlaceToken;
      this.mainRootToken = data.mainRootToken;
      this.rankingUnderParent = data.rankingUnderParent;
      this.parentPlaceData = data.parentPlaceData;
      this.systemComponentToken = data.systemComponentToken;
      this.systemComponentHierarchyToken = data.systemComponentHierarchyToken;
      this.systemComponentData.fillData(data.systemComponentData);
      this.systemComponentsHierarchyData.fillData(
        data.systemComponentsHierarchyData
      );
      this.isHaveChildrenStatus = data.isHaveChildrenStatus;
      this.fullTreeData.fillData(data.fullTreeData);
    } else {
      this.setInitialValue();
    }
  }
}
//#endregion model
