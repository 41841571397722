var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
            _vm.complaintFollowUp.complaintFollowUpMediaPath,
            _vm.complaintFollowUp.defaultImg
          ),"deleteFileStatus":_vm.deleteFileStatus &&
          !_vm.complaintFollowUp.complaintFollowUpImageIsDefault &&
          _vm.checkPrivilege(_vm.hasComplaintFollowUpDeleteImage())},on:{"changeValue":function($event){_vm.complaintFollowUp.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.complaintFollowUp.complaintInfoData.complaintTitleCurrent,"title":_vm.$t('Complaints.title'),"imgName":'complaints.svg'}}),_c('CustomSelectBox',{attrs:{"className":'col-md-6',"id":(_vm.id + "-complaintTypeToken"),"value":_vm.complaintFollowUp.complaintTypeToken,"options":_vm.complaintFollowUpTypeTokenOptions,"title":_vm.$t('ComplaintTypes.select'),"imgName":'complaintTypes.svg'},on:{"changeValue":function($event){_vm.complaintFollowUp.complaintTypeToken = $event}}}),_c('CustomSelectBox',{attrs:{"className":'col-md-6',"id":(_vm.id + "-complaintSectorToken"),"value":_vm.complaintFollowUp.complaintSectorToken,"options":_vm.complaintFollowUpSectorTokenOptions,"title":_vm.$t('ComplaintSectors.select'),"imgName":'complaintSectors.svg'},on:{"changeValue":function($event){_vm.complaintFollowUp.complaintSectorToken = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-fullCode"),"value":_vm.complaintFollowUp.fullCode,"title":_vm.$t('ComplaintFollowUps.code'),"imgName":'code.svg'},on:{"changeValue":function($event){_vm.complaintFollowUp.fullCode = $event}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-complaintFollowUpTextAr"),"errors":_vm.errors_complaintFollowUpTextAr,"value":_vm.complaintFollowUp.complaintFollowUpTextAr,"title":_vm.$t('ComplaintFollowUps.textAr'),"imgName":'complaintFollowUps.svg'},on:{"changeValue":function($event){_vm.complaintFollowUp.complaintFollowUpTextAr = $event;
            _vm.$v.complaintFollowUp.complaintFollowUpTextAr.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-complaintFollowUpTextEn"),"errors":_vm.errors_complaintFollowUpTextEn,"value":_vm.complaintFollowUp.complaintFollowUpTextEn,"title":_vm.$t('ComplaintFollowUps.textEn'),"imgName":'complaintFollowUps.svg'},on:{"changeValue":function($event){_vm.complaintFollowUp.complaintFollowUpTextEn = $event;
            _vm.$v.complaintFollowUp.complaintFollowUpTextEn.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-12',"id":(_vm.id + "-complaintFollowUpTextUnd"),"value":_vm.complaintFollowUp.complaintFollowUpTextUnd,"title":_vm.$t('ComplaintFollowUps.textUnd'),"imgName":'complaintFollowUps.svg'},on:{"changeValue":function($event){_vm.complaintFollowUp.complaintFollowUpTextUnd = $event}}})],1)]),_c('div',{staticClass:"form-footer"},[_c('button',{staticClass:"btn btn-submit",attrs:{"name":"submit","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.submitName)+" ")]),_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("ConfirmCloseSheet-" + _vm.bottomSheetName)),expression:"`ConfirmCloseSheet-${bottomSheetName}`"}],staticClass:"btn btn-cancel",on:{"click":function($event){$event.preventDefault();}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }